import { lazy } from 'react'
import { Navigate, Route } from 'react-router-dom'
import Routes from 'components/Routes/Routes'

const Dashboard = lazy(() => import('pages/affiliates/dashboard/dashboard'))
const DashboardNews = lazy(() =>
  import('pages/affiliates/dashboard/dashboardNews')
)

const Offers = lazy(() => import('pages/affiliates/offers/offers'))
const All = lazy(() => import('components/Affiliates/Offers/All'))
const Live = lazy(() => import('components/Affiliates/Offers/Live'))
const Available = lazy(() => import('components/Affiliates/Offers/Available'))
const S2sDetail = lazy(() => import('pages/affiliates/offers/s2sDetail'))
const ApiDetail = lazy(() => import('pages/affiliates/offers/apiDetail'))

const Tickets = lazy(() => import('pages/affiliates/tickets'))
const Payments = lazy(() => import('pages/affiliates/payments'))

//------- Statistic -------
const Statistic = lazy(() => import('pages/affiliates/statistic'))
// const StatisticDetails = lazy(() => import('pages/affiliates/statisticDetails'))
//------- S2s -------
const GeneralS2s = lazy(() =>
  import('components/Affiliates/Statistic/S2s/Genaral/General')
)
const СlicksS2s = lazy(() =>
  import('components/Affiliates/Statistic/S2s/Сlicks/Сlicks')
)
const ConversionS2s = lazy(() =>
  import('components/Affiliates/Statistic/S2s/Conversion/Conversion')
)
//------------------------------
//------- Api -------
const GeneralApi = lazy(() =>
  import('components/Affiliates/Statistic/Api/Genaral/General')
)
const СlicksApi = lazy(() =>
  import('components/Affiliates/Statistic/Api/Сlicks/Сlicks')
)
const UserSessionApi = lazy(() =>
  import('components/Affiliates/Statistic/Api/UserSession/UserSession')
)
const ConversionApi = lazy(() =>
  import('components/Affiliates/Statistic/Api/Conversion/Conversion')
)
//------------------------------
//------------------------------

const Settings = lazy(() => import('pages/affiliates/settings/settings'))
const Profile = lazy(() => import('components/Affiliates/Settings/Profile'))
const SettingsPayments = lazy(() =>
  import('components/Affiliates/Settings/Payments')
)
const Postbacks = lazy(() => import('components/Affiliates/Settings/Postbacks'))
const Referrals = lazy(() => import('components/Affiliates/Settings/Referrals'))
const Security = lazy(() => import('components/Affiliates/Settings/Security'))

const AffiliatesRoutes = (
  <>
    <Route path={Routes.dashboard.index} element={<Dashboard />} key="/dashboard" />
    <Route path={Routes.dashboard.news} element={<DashboardNews />} />

    <Route path={Routes.offers.index} element={<Offers />}>
      <Route index element={<Navigate to="/offers/all" />} />
      <Route path="all" element={<All />} />
      <Route path="live" element={<Live />} />
      <Route path="available" element={<Available />} />
    </Route>

    <Route
      path={Routes.offers.direct.detail}
      element={<S2sDetail />}
      key="/offers/direct-link/detail/:id"
    />

    <Route
      path={Routes.offers.api.detail}
      element={<ApiDetail />}
      key="/offers/api/detail/:id"
    />

    <Route path="/payments" element={<Payments />} key="/payments" />

    <Route path="/tickets" element={<Tickets />} key="/tickets" />

    <Route path="/statistic" element={<Statistic />}>
      <Route index element={<Navigate to="/statistic/direct-link/general" />} />
      <Route path="direct-link">
        <Route index element={<Navigate to="general" />} />
        <Route path="general" element={<GeneralS2s />} />
        <Route path="clicks" element={<СlicksS2s />} />
        <Route path="conversion" element={<ConversionS2s />} />
      </Route>
      <Route path="api">
        <Route index element={<Navigate to="general" />} />
        <Route path="general" element={<GeneralApi />} />
        <Route path="clicks" element={<СlicksApi />} />
        <Route path="user-session" element={<UserSessionApi />} />
        <Route path="conversion" element={<ConversionApi />} />
      </Route>
    </Route>
    {/* <Route path="/statistic/details/:id" element={<StatisticDetails />} /> */}

    <Route path="/settings" element={<Settings />}>
      <Route index element={<Navigate to="/settings/profile" />} />
      <Route path="profile" element={<Profile />} />
      <Route path="payments" element={<SettingsPayments />} />
      <Route path="postbacks" element={<Postbacks />} />
      <Route path="referrals" element={<Referrals />} />
      <Route path="security" element={<Security />} />
    </Route>
  </>
)

export default AffiliatesRoutes
