import { lazy } from 'react'
import { Navigate, Route } from 'react-router-dom'

import Routes from 'components/Routes/Routes'

const Dashboard = lazy(() => import('pages/manager/dashboard'))

//------- Roles -------
const Roles = lazy(() => import('pages/manager/roles'))
const Affiliates = lazy(() => import('components/Admin/Roles/Affiliates'))
const AddAffiliate = lazy(() => import('pages/admin/roles/addAffiliate'))
const Advertisers = lazy(() => import('components/Admin/Roles/Advertisers'))
const AddAdvertiser = lazy(() => import('pages/admin/roles/addAdvertiser'))
//------------------------------

//------- Offers -------
const Offers = lazy(() => import('pages/admin/offers'))
const Adds2s = lazy(() => import('pages/admin/offers/adds2s'))
const Addapi = lazy(() => import('pages/admin/offers/addapi'))
const S2sDetail = lazy(() => import('pages/admin/offers/s2sDetail'))
const ApiDetail = lazy(() => import('pages/admin/offers/apiDetail'))
//------------------------------

//------- Statistic -------
const Statistic = lazy(() => import('pages/admin/statistic'))
//------- Api -------
const GeneralApi = lazy(() => import('components/Admin/Statistic/Api/Genaral/General'))
const СlicksApi = lazy(() => import('components/Admin/Statistic/Api/Сlicks/Сlicks'))
const UserSessionApi = lazy(() => import('components/Admin/Statistic/Api/UserSession/UserSession'))
const ConversionApi = lazy(() => import('components/Admin/Statistic/Api/Conversion/Conversion'))
//------------------------------

//------- S2s -------
const GeneralS2s = lazy(() => import('components/Admin/Statistic/S2s/Genaral/General'))
const СlicksS2s = lazy(() => import('components/Admin/Statistic/S2s/Сlicks/Сlicks'))
const ConversionS2s = lazy(() => import('components/Admin/Statistic/S2s/Conversion/Conversion'))
//------------------------------


const ManagerRoutes = (
  <>
    <Route path={Routes.dashboard.index} element={<Dashboard />} />

    <Route path={Routes.roles.index} element={<Roles />}>
      <Route index element={<Navigate to={Routes.roles.affiliate.index} />} />

      <Route path={Routes.roles.affiliate.index} element={<Affiliates />} />
      <Route path={Routes.roles.advertiser.index} element={<Advertisers />} />
    </Route>

    <Route path={Routes.roles.affiliate.create} element={<AddAffiliate />} />
    <Route path={Routes.roles.affiliate.edit} element={<AddAffiliate />} />
    <Route path={Routes.roles.advertiser.create} element={<AddAdvertiser />} />
    <Route path={Routes.roles.advertiser.edit} element={<AddAdvertiser />} />


    <Route path={Routes.offers.index} element={<Offers />} />
    <Route path={Routes.offers.direct.create} element={<Adds2s />} />
    <Route path={Routes.offers.direct.edit} element={<Adds2s />} />
    <Route path={Routes.offers.api.create} element={<Addapi />} />
    <Route path={Routes.offers.api.edit} element={<Addapi />} />
    <Route path={Routes.offers.direct.detail} element={<S2sDetail />} />
    <Route path={Routes.offers.api.detail} element={<ApiDetail />} />

    <Route path={Routes.statistic.index} element={<Statistic />}>
      <Route index element={<Navigate to={Routes.statistic.directLink.general} />} />

      <Route path={Routes.statistic.directLink.index}>
        <Route index element={<Navigate to={Routes.statistic.directLink.general} />} />

        <Route path={Routes.statistic.directLink.general} element={<GeneralS2s />} />
        <Route path={Routes.statistic.directLink.clicks} element={<СlicksS2s />} />
        <Route path={Routes.statistic.directLink.conversion.index} element={<ConversionS2s />} />
      </Route>

      <Route path={Routes.statistic.api.index}>
        <Route index element={<Navigate to={Routes.statistic.api.general} />} />

        <Route path={Routes.statistic.api.general} element={<GeneralApi />} />
        <Route path={Routes.statistic.api.clicks} element={<СlicksApi />} />
        <Route path={Routes.statistic.api.userSession} element={<UserSessionApi />} />
        <Route path={Routes.statistic.api.conversion.index} element={<ConversionApi />} />
      </Route>
    </Route>
  </>
)

export default ManagerRoutes
