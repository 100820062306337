import { useState, useRef } from 'react'

import { generateUUID } from 'components/common/utils/helpers'
import useOutsideClick from 'components/hooks/useOutsideClick'

import styled from 'styled-components'

import iconInfo from 'images/icons/info-black.svg'

const Wrap = styled.div`
  position: relative;
`
const Label = styled.label`
  position: relative;
  display: inline-block;
  width: ${({ width }) => (width ? width : '100%')};
`

const InfoWrap = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 8px;
`

const LabelText = styled.p`
  width: fit-content;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`

const Input = styled.input`
  width: 100%;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  padding: 8px;
  padding-right: 12px;
  padding-right: 27px;
  background: var(--white);

  border: 1px solid var(--bright);

  &::placeholder {
    color: ${({ placeholderStyle }) => placeholderStyle && 'var(--primary-3)'};
  }
`

const CrossWrap = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0px;
  right: 36px;
  padding: 4px 10px 9px;
  cursor: pointer;
  opacity: 0.6;

  :hover {
    opacity: 1;
  }
`

const ImgArrow = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 1px;
  right: 2px;
  padding: 14px 10px 12px;
  cursor: pointer;
  opacity: 0.4;

  transform: ${({ rotate }) => rotate && 'rotate(180deg)'};

  :hover {
    opacity: 1;
  }
`

const DropDown = styled.div`
  position: absolute;
  z-index: 10;
  display: block;
  width: 100%;
  height: 144px;
  padding: 9px 12px 0 12px;
  overflow: scroll;
  background-color: var(--white);
  top: ${({ label }) => (label ? '63px' : '42px')};
  left: 0;
  border: 1px solid var(--bright);
  box-shadow: 0px 4px #d7dff6;

  ::-webkit-scrollbar {
    width: 6px;
    background-color: var(--white);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--bright);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--blue);
  }

  ::-webkit-scrollbar-thumb:active {
    background: #25477a;
  }
`

const DropDownBlock = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;

  padding: 2px 4px;
  margin-right: 4px;
  margin-bottom: 4px;

  background: var(--secondary-1);
`

const DropDownText = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
`

const TextCross = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  opacity: 0.6;

  :hover {
    opacity: 1;
  }
`

const ImgInfo = styled.img`
  display: inline-block;
`

const EnterInput = ({
                      label,
                      width,
                      name,
                      handleChange,
                      placeholder,
                      required,
                      title,
                      disabled,
                      infoIcon,
                      initialValue
                    }) => {
  const [data, setData] = useState(initialValue || [])
  const [isShowDropdown, setIsShowDropdown] = useState(false)

  const changeWrapRef = useRef(null)
  useOutsideClick(changeWrapRef, () => setIsShowDropdown(false))

  const laceholderValue = !isShowDropdown && data.length ? data : placeholder
  const isPlaceholderCUstom = data.length && !isShowDropdown

  const removeText = (index) => {
    const dataCopy = [...data]
    dataCopy.splice(index, 1)
    setData(dataCopy)

    if (handleChange) handleChange(dataCopy)

  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const target = e.target
      const value = e.target.value.trim()

      if (!value) {
        return
      }

      const dataCopy = [...data]

      dataCopy.push(value)
      setData(dataCopy)

      if (handleChange) handleChange(dataCopy)
      target.value = ''
    }
  }

  return (
    <Wrap ref={changeWrapRef}>
      <Label width={width}>
        {label && (
          <InfoWrap>
            <LabelText>{label}</LabelText>
            {infoIcon && (
              <ImgInfo src={iconInfo} width="10" height="10" alt="info" />
            )}
          </InfoWrap>
        )}

        <Input
          type="text"
          name={name}
          placeholder={laceholderValue}
          placeholderStyle={isPlaceholderCUstom}
          required={required}
          title={title}
          disabled={disabled}
          onClick={() => setIsShowDropdown(true)}
          onKeyDown={handleKeyDown}
        />

        {!isShowDropdown && (
          <ImgArrow>
            <svg width="12" height="8" viewBox="0 0 16 10">
              <path
                d="M7.98693 9.99996L0.333684 2.04112C-0.111228 1.57844 -0.111227 0.828297 0.333685 0.365619C0.778597 -0.0970592 1.49994 -0.0970602 1.94485 0.365618L9.5981 8.32446L7.98693 9.99996Z"
                fill="#0B1C3E"
              />
              <path
                d="M6.38398 8.3245L14.0551 0.347009C14.5001 -0.115669 15.2214 -0.11567 15.6663 0.347008C16.1112 0.809686 16.1112 1.55984 15.6663 2.02251L7.99515 10L6.38398 8.3245Z"
                fill="#0B1C3E"
              />
            </svg>
          </ImgArrow>
        )}
      </Label>

      {!!data.length && (
        <CrossWrap
          onClick={() => {
            setData([])
            setIsShowDropdown(false)
            if (handleChange) handleChange([])
          }}
        >
          <svg width="16" height="16" viewBox="0 0 24 24">
            <path
              d="M7.1 18.3C6.7134 18.6866 6.0866 18.6866 5.7 18.3C5.3134 17.9134 5.3134 17.2866 5.7 16.9L10.6 12L5.7 7.1C5.3134 6.7134 5.3134 6.0866 5.7 5.7C6.0866 5.3134 6.7134 5.3134 7.1 5.7L12 10.6L16.9 5.7C17.2866 5.3134 17.9134 5.3134 18.3 5.7C18.6866 6.0866 18.6866 6.7134 18.3 7.1L13.4 12L18.3 16.9C18.6866 17.2866 18.6866 17.9134 18.3 18.3C17.9134 18.6866 17.2866 18.6866 16.9 18.3L12 13.4L7.1 18.3Z"
              fill="#18356f"
            />
          </svg>
        </CrossWrap>
      )}
      {isShowDropdown && (
        <ImgArrow onClick={() => setIsShowDropdown(false)} rotate>
          <svg width="12" height="8" viewBox="0 0 16 10">
            <path
              d="M7.98693 9.99996L0.333684 2.04112C-0.111228 1.57844 -0.111227 0.828297 0.333685 0.365619C0.778597 -0.0970592 1.49994 -0.0970602 1.94485 0.365618L9.5981 8.32446L7.98693 9.99996Z"
              fill="#0B1C3E"
            />
            <path
              d="M6.38398 8.3245L14.0551 0.347009C14.5001 -0.115669 15.2214 -0.11567 15.6663 0.347008C16.1112 0.809686 16.1112 1.55984 15.6663 2.02251L7.99515 10L6.38398 8.3245Z"
              fill="#0B1C3E"
            />
          </svg>
        </ImgArrow>
      )}

      {isShowDropdown && (
        <>
          {data && (
            <DropDown label={label}>
              {data.map((el, i) => (
                <DropDownBlock key={generateUUID()}>
                  <DropDownText>{el}</DropDownText>
                  <TextCross onClick={() => removeText(i)}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      className="unclosed"
                    >
                      <path
                        d="M7.1 18.3C6.7134 18.6866 6.0866 18.6866 5.7 18.3C5.3134 17.9134 5.3134 17.2866 5.7 16.9L10.6 12L5.7 7.1C5.3134 6.7134 5.3134 6.0866 5.7 5.7C6.0866 5.3134 6.7134 5.3134 7.1 5.7L12 10.6L16.9 5.7C17.2866 5.3134 17.9134 5.3134 18.3 5.7C18.6866 6.0866 18.6866 6.7134 18.3 7.1L13.4 12L18.3 16.9C18.6866 17.2866 18.6866 17.9134 18.3 18.3C17.9134 18.6866 17.2866 18.6866 16.9 18.3L12 13.4L7.1 18.3Z"
                        fill="#18356f"
                      />
                    </svg>
                  </TextCross>
                </DropDownBlock>
              ))}
            </DropDown>
          )}
        </>
      )}
    </Wrap>
  )
}

export default EnterInput
