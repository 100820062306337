import { lazy } from 'react'
import { Navigate, Route } from 'react-router-dom'

import Routes from 'components/Routes/Routes'

const Dashboard = lazy(() => import('pages/admin/dashboard'))
const Test = lazy(() => import('pages/admin/FeaturesTest'))

//------- Roles -------
const Roles = lazy(() => import('pages/admin/roles'))
const Affiliates = lazy(() => import('components/Admin/Roles/Affiliates'))
const AddAffiliate = lazy(() => import('pages/admin/roles/addAffiliate'))
const Advertisers = lazy(() => import('components/Admin/Roles/Advertisers'))
const AddAdvertiser = lazy(() => import('pages/admin/roles/addAdvertiser'))
const Managers = lazy(() => import('components/Admin/Roles/Managers'))
const AddManagers = lazy(() => import('pages/admin/roles/addManagers'))
//------------------------------

//------- Distribution -------
const Distribution = lazy(() => import('pages/admin/distribution/distribution'))
const ReDistribution = lazy(() => import('components/Admin/ColdApi/ReDistribution'))
const DistributionList = lazy(() => import('pages/admin/distribution/distributionList'))
const DistributionRules = lazy(() => import('components/Admin/ColdApi/DistributionRules'))

const LeadList = lazy(() => import('components/Admin/ColdApi/LeadList'))

const DistributionLeadListDetails = lazy(() => import('pages/admin/distribution/distributionLeadListDetails'))
const LeadListUserSessions = lazy(() => import('components/Admin/ColdApi/LeadList/Details/UserSession'))
const LeadListConversion = lazy(() => import('components/Admin/ColdApi/LeadList/Details/Conversion'))

const DistributionLeadListAdd = lazy(() => import('pages/admin/distribution/distributionLeadListAdd'))
//------------------------------

//------- Billing -------
const Billing = lazy(() => import('pages/admin/billing/billing'))
const InvoiceCreate = lazy(() => import('pages/admin/billing/invoiceCreate'))
const InvoiceDetail = lazy(() => import('pages/admin/billing/invoiceDetail'))
//------------------------------

//------- Landings -------
const Landings = lazy(() => import('pages/admin/landings'))
const LandingsDetails = lazy(() => import('pages/admin/landings/landingsAdd'))
//------------------------------

//------- Offers -------
const Offers = lazy(() => import('pages/admin/offers'))
const Adds2s = lazy(() => import('pages/admin/offers/adds2s'))
const Addapi = lazy(() => import('pages/admin/offers/addapi'))
const S2sDetail = lazy(() => import('pages/admin/offers/s2sDetail'))
const ApiDetail = lazy(() => import('pages/admin/offers/apiDetail'))
//------------------------------

//------- Statistic -------
const Statistic = lazy(() => import('pages/admin/statistic'))
const StatisticDetails = lazy(() => import('pages/admin/statisticDetails'))
//------- Api -------
const GeneralApi = lazy(() => import('components/Admin/Statistic/Api/Genaral/General'))
const СlicksApi = lazy(() => import('components/Admin/Statistic/Api/Сlicks/Сlicks'))
const UserSessionApi = lazy(() => import('components/Admin/Statistic/Api/UserSession/UserSession'))
const ConversionApi = lazy(() => import('components/Admin/Statistic/Api/Conversion/Conversion'))
//------------------------------
//------- S2s -------
const GeneralS2s = lazy(() => import('components/Admin/Statistic/S2s/Genaral/General'))
const СlicksS2s = lazy(() => import('components/Admin/Statistic/S2s/Сlicks/Сlicks'))
const ConversionS2s = lazy(() => import('components/Admin/Statistic/S2s/Conversion/Conversion'))
//------------------------------

//------- Postbacks -------
const Postbacks = lazy(() => import('pages/admin/postbacks'))
//------------------------------

//------- Settings -------
const Settings = lazy(() => import('pages/admin/settings/settings'))
const SettingsGeneral = lazy(() => import('components/Admin/Settings/General'))
const Domains = lazy(() => import('components/Admin/Settings/Domains'))
const Smtp = lazy(() => import('components/Admin/Settings/Smtp'))
const Postback = lazy(() => import('components/Admin/Settings/Postback'))
const SettingsAffiliates = lazy(() => import('components/Admin/Settings/Affiliates'))
const Registration = lazy(() => import('components/Admin/Settings/Registration'))
const News = lazy(() => import('components/Admin/Settings/News'))
const NewsAdd = lazy(() => import('components/Admin/Settings/NewsAdd'))
const Personal = lazy(() => import('components/Admin/Settings/Personal/Personal'))
const TestApi = lazy(() => import('components/Admin/Settings/TestApi'))
const TestIntegrations = lazy(() => import('components/Admin/Settings/TestApi/TestIntegrations'))
const TestOffer = lazy(() => import('components/Admin/Settings/TestApi/TestOffer'))
const PersonalLogs = lazy(() => import('pages/admin/settings/logs'))
const TestDirectLink = lazy(() => import('components/Admin/Settings/TestApi/TestDirectLink'))
//------------------------------

const AdminRoutes = (
  <>
    <Route path={Routes.dashboard.index} element={<Dashboard />} />
    <Route path={Routes.test.index} element={<Test />} />

    <Route path={Routes.coldApi.index} element={<Distribution />}>
      <Route index element={<Navigate to={Routes.coldApi.reColdApi.index} />} />

      <Route path={Routes.coldApi.reColdApi.index} element={<ReDistribution />} />
      <Route path={Routes.coldApi.rules} element={<DistributionRules />} />
      <Route path={Routes.coldApi.leadList.index} element={<LeadList />} />
    </Route>
    <Route path={Routes.coldApi.reColdApi.list} element={<DistributionList />} />
    <Route path={Routes.coldApi.leadList.details.index} element={<DistributionLeadListDetails />}>
      <Route index element={<Navigate to={Routes.coldApi.leadList.details.userSession} />} />

      <Route path={Routes.coldApi.leadList.details.userSession} element={<LeadListUserSessions />} />
      <Route path={Routes.coldApi.leadList.details.conversion} element={<LeadListConversion />} />
    </Route>

    <Route path={Routes.coldApi.leadList.add.index} element={<DistributionLeadListAdd />}>
      <Route index element={<Navigate to={Routes.coldApi.leadList.add.userSession} />} />

      <Route path={Routes.coldApi.leadList.add.userSession} element={<LeadListUserSessions />} />
      <Route path={Routes.coldApi.leadList.add.conversion} element={<LeadListConversion />} />
    </Route>

    <Route path={Routes.roles.index} element={<Roles />}>
      <Route index element={<Navigate to={Routes.roles.affiliate.index} />} />

      <Route path={Routes.roles.affiliate.index} element={<Affiliates />} />
      <Route path={Routes.roles.advertiser.index} element={<Advertisers />} />
      <Route path={Routes.roles.manager.index} element={<Managers />} />
    </Route>

    <Route path={Routes.roles.affiliate.create} element={<AddAffiliate />} />
    <Route path={Routes.roles.affiliate.edit} element={<AddAffiliate />} />
    <Route path={Routes.roles.advertiser.create} element={<AddAdvertiser />} />
    <Route path={Routes.roles.advertiser.edit} element={<AddAdvertiser />} />
    <Route path={Routes.roles.manager.create} element={<AddManagers />} />
    <Route path={Routes.roles.manager.edit} element={<AddManagers />} />

    <Route path={Routes.billing.index} element={<Billing />} />

    <Route path={Routes.billing.invoice.create} element={<InvoiceCreate />} />
    <Route path={Routes.billing.invoice.edit} element={<InvoiceCreate />} />
    <Route path={Routes.billing.invoice.delete} element={<InvoiceCreate />} />
    <Route path={Routes.billing.invoice.detail} element={<InvoiceDetail />} />

    <Route path={Routes.landings.index} element={<Landings />} />
    <Route path={Routes.landings.create} element={<LandingsDetails />} />
    <Route path={Routes.landings.edit} element={<LandingsDetails />} />

    <Route path={Routes.offers.index} element={<Offers />} />
    <Route path={Routes.offers.direct.create} element={<Adds2s />} />
    <Route path={Routes.offers.direct.edit} element={<Adds2s />} />
    <Route path={Routes.offers.direct.detail} element={<S2sDetail />} />

    <Route path={Routes.offers.api.create} element={<Addapi />} />
    <Route path={Routes.offers.api.edit} element={<Addapi />} />
    <Route path={Routes.offers.api.detail} element={<ApiDetail />} />

    <Route path={Routes.statistic.index} element={<Statistic />}>
      <Route index element={<Navigate to={Routes.statistic.directLink.general} />} />

      <Route path={Routes.statistic.directLink.index}>
        <Route index element={<Navigate to={Routes.statistic.directLink.general} />} />

        <Route path={Routes.statistic.directLink.general} element={<GeneralS2s />} />
        <Route path={Routes.statistic.directLink.clicks} element={<СlicksS2s />} />
        <Route path={Routes.statistic.directLink.conversion.index} element={<ConversionS2s />} />
      </Route>

      <Route path={Routes.statistic.api.index}>
        <Route index element={<Navigate to={Routes.statistic.api.general} />} />

        <Route path={Routes.statistic.api.general} element={<GeneralApi />} />
        <Route path={Routes.statistic.api.clicks} element={<СlicksApi />} />
        <Route path={Routes.statistic.api.userSession} element={<UserSessionApi />} />
        <Route path={Routes.statistic.api.conversion.index} element={<ConversionApi />} />
      </Route>
    </Route>

    <Route path={Routes.statistic.directLink.conversion.detail} element={<StatisticDetails />} />
    <Route path={Routes.statistic.api.conversion.detail} element={<StatisticDetails />} />
    <Route path={Routes.statistic.postbacks} element={<Postbacks />} />


    <Route path={Routes.settings.index} element={<Settings />}>
      <Route index element={<Navigate to={Routes.settings.general} />} />

      <Route path={Routes.settings.general} element={<SettingsGeneral />} />
      <Route path={Routes.settings.domains} element={<Domains />} />
      <Route path={Routes.settings.smtp} element={<Smtp />} />
      <Route path={Routes.settings.postback} element={<Postback />} />
      <Route path={Routes.settings.affiliates} element={<SettingsAffiliates />} />
      <Route path={Routes.settings.registration} element={<Registration />} />
      <Route path={Routes.settings.news.index} element={<News />} />
      <Route path={Routes.settings.news.create} element={<NewsAdd />} />
      <Route path={Routes.settings.news.edit} element={<NewsAdd />} />
      <Route path={Routes.settings.personal.index} element={<Personal />} />

      <Route path={Routes.settings.testApi.index} element={<TestApi />}>
        <Route index element={<Navigate to={Routes.settings.testApi.integrations} />} />

        <Route path={Routes.settings.testApi.integrations} element={<TestIntegrations />} />
        <Route path={Routes.settings.testApi.offer} element={<TestOffer />} />
        <Route path={Routes.settings.testApi.directLink} element={<TestDirectLink />} />
      </Route>
    </Route>

    <Route path={Routes.settings.personal.logs} element={<PersonalLogs />} />
  </>
)

export default AdminRoutes
