export default class Routes {
  static PARAM_SPLITTER = /:\w+/g

  static dashboard = {
    index: '/dashboard'
  }

  static roles = {
    index: '/roles',
    affiliates: {
      index: '/roles/affiliates',
      create: '/roles/affiliates/create',
      edit: '/roles/affiliates/edit/:uuid'
    },
    advertisers: {
      index: '/roles/advertisers',
      create: '/roles/advertisers/create',
      edit: '/roles/advertisers/edit/:uuid'
    },
    managers: {
      index: '/roles/managers',
      create: '/roles/managers/create',
      edit: '/roles/managers/edit/:uuid'
    }
  }

  static coldApi = {
    index: '/cold-api',
    reColdApi: '/cold-api/re-cold-api',
    leadList: '/cold-api/lead-list'
  }

  static offer = {
    index: '/offer',
    direct: {
      create: '/offer/direct/create',
      edit: '/offer/direct/edit/:uuid',
      detail: '/offer/direct/detail/:uuid'
    },
    api: {
      create: '/offer/api/create',
      edit: '/offer/api/edit/:uuid',
      detail: '/offer/api/detail/:uuid'
    }
  }

  static statistic = {
    index: '/cold-api',
    postbacks: '/statistic/postbacks'
  }

  static getUrl(path, params = {}) {
    const dynamicKeys = path.match(Routes.PARAM_SPLITTER) || []

    const missingKeys = dynamicKeys.filter(
      (key) => !Object.keys(params).includes(key.slice(1))
    )

    if (missingKeys.length > 0) {
      console.warn(
        `Missing parameters for URL generation: ${missingKeys.map((key) => key.slice(1)).join(', ')}`
      )

      return '/'
    }

    return dynamicKeys.reduce(
      (url, key) => url.replace(key, params[key.slice(1)] || key),
      path
    )
  }
}

/*
// Корректный случай
const editUrl = Routes.getUrl(Routes.offer.direct.edit, { uuid: '12345' });
console.log(editUrl); // "/offer/direct/edit/12345"

// Ошибка: отсутствуют параметры
const invalidUrl = Routes.getUrl(Routes.offer.api.edit);
console.log(invalidUrl); // "/error" + предупреждение в консоль
*/
