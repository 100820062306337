import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, useNavigate } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from 'redux/store'
import { viewPortSize } from 'redux/info-slice'

import * as Sentry from '@sentry/react'

import App from './components/App'

import { StyleSheetManager } from 'styled-components'
import 'styles/index.css'

const isLocalhost = window.location.hostname === 'localhost'
if (!isLocalhost) {
  Sentry.init({
    dsn: 'https://796bd6e2f478f56a3bd23030d58c7b43@sentry.marksel.tech/3',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    debug: false,
    beforeSend(event, hint) {
      const error = hint?.originalException

      if (error && error.response) {
        const statusCode = error.response.status
        if (statusCode >= 400 && statusCode < 600) {
          return null
        }
      }

      return event
    }
  })
}


const MyComponent = () => {
  const navigate = useNavigate()

  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    const handleError = (event) => {
      console.log('Error event:', event)
      setHasError(true)
    }

    window.addEventListener('error', handleError)

    return () => {
      window.removeEventListener('error', handleError)
    }
  }, [])

  useEffect(() => {
    store.dispatch(viewPortSize(window.innerWidth))

    function handleResize() {
      store.dispatch(viewPortSize(window.innerWidth))
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // if (hasError) {
  //   return (
  //     <div
  //       onClick={() => {
  //         navigate(-1)
  //         setHasError(false)
  //       }}
  //       className="wrap-error"
  //     >
  //       Something went wrong.
  //       <RouterBack />
  //     </div>
  //   )
  // }

  return <App />
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <StyleSheetManager shouldForwardProp={(prop) => true}>
        <MyComponent />
      </StyleSheetManager>
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
)
