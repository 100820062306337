export default class Routes {
  static PARAM_SPLITTER = /:\w+/g

  static test = {
    index: '/test'
  }

  static dashboard = {
    index: '/dashboard',
    news: '/dashboard/news',
  }

  // import Routes from 'components/Routes/Routes'
  // Routes.settings.index
  // Routes.getUrl(Routes.roles.advertiser.create)
  // Routes.getUrl(Routes.roles.advertiser.create.edit, { uuid })
  static roles = {
    index: '/roles',
    affiliate: {
      index: '/roles/affiliates',
      create: '/roles/affiliates/create',
      edit: '/roles/affiliates/edit/:uuid'
    },
    advertiser: {
      index: '/roles/advertisers',
      create: '/roles/advertisers/create',
      edit: '/roles/advertisers/edit/:uuid'
    },
    manager: {
      index: '/roles/managers',
      create: '/roles/managers/create',
      edit: '/roles/managers/edit/:uuid'
    }
  }

  static billing = {
    index: '/billing',
    invoice: {
      create: '/billing/invoice-create',
      edit: '/billing/invoice-edit/:uuid',
      delete: '/billing/invoice-delete/:uuid',
      detail: '/billing/invoice-detail/:uuid'
    }
  }

  static landings = {
    index: '/landings',
    create: '/landings/create',
    edit: '/landings/edit/:uuid'
  }

  static offers = {
    index: '/offers',
    direct: {
      create: '/offers/create-direct-link',
      edit: '/offers/edit-direct-link/:uuid',
      detail: '/offers/detail-direct-link/:uuid'
    },
    api: {
      create: '/offers/create-api',
      edit: '/offers/edit-api/:uuid',
      detail: '/offers/detail-api/:uuid'
    }
  }

  static statistic = {
    index: '/statistic',
    postbacks: '/statistic/postbacks',
    directLink: {
      index: '/statistic/direct-link',
      general: '/statistic/direct-link/general',
      clicks: '/statistic/direct-link/clicks',
      conversion: {
        index: '/statistic/direct-link/conversion',
        detail: '/statistic/direct-link/conversion/detail/:uuid'
      }
    },
    api: {
      index: '/statistic/api',
      general: '/statistic/api/general',
      clicks: '/statistic/api/clicks',
      userSession: '/statistic/api/user-session',
      conversion: {
        index: '/statistic/api/conversion',
        detail: '/statistic/api/conversion/detail/:uuid'
      }
    }
  }

  static coldApi = {
    index: '/cold-api',
    reColdApi: {
      index: '/cold-api/re-cold-api',
      list: '/cold-api/re-cold-api/list/:uuid'
    },
    rules: '/cold-api/rules',
    leadList: {
      index: '/cold-api/lead-list',
      add: {
        index: '/cold-api/lead-list/add',
        userSession: '/cold-api/lead-list/add/user-session/:uuid',
        conversion: '/cold-api/lead-list/add/conversion/:uuid'
      },
      details: {
        index: '/cold-api/lead-list/details',
        userSession: '/cold-api/lead-list/details/user-session/:uuid',
        conversion: '/cold-api/lead-list/details/conversion/:uuid'
      }
    }
  }

  static settings = {
    index: '/settings',
    general: '/settings/general',
    domains: '/settings/domains',
    smtp: '/settings/smtp',
    postback: '/settings/postback',
    affiliates: '/settings/affiliates',
    registration: '/settings/registration',
    news: {
      index: '/settings/news',
      create: '/settings/news/create',
      edit: '/settings/news/edit/:uuid'
    },
    personal: {
      index: '/settings/personal',
      logs: '/settings/personal/logs'
    },
    testApi: {
      index: '/settings/test-api',
      integrations: '/settings/test-api/integrations',
      offer: '/settings/test-api/offer',
      directLink: '/settings/test-api/direct-link'
    }
  }

  static getUrl(path, params = {}) {
    const dynamicKeys = path.match(Routes.PARAM_SPLITTER) || []

    const missingKeys = dynamicKeys.filter(
      (key) => !Object.keys(params).includes(key.slice(1))
    )

    if (missingKeys.length > 0) {
      console.warn(
        `Missing parameters for URL generation: ${missingKeys
          .map((key) => key.slice(1))
          .join(', ')}`
      )

      return '/'
    }

    return dynamicKeys.reduce(
      (url, key) => url.replace(key, params[key.slice(1)] || key),
      path
    )
  }
}

/*
// Корректный случай
const editUrl = Routes.getUrl(Routes.offers.direct.edit, { uuid: '12345' });
console.log(editUrl); // "/offer/direct/edit/12345"

// Ошибка: отсутствуют параметры
const invalidUrl = Routes.getUrl(Routes.offers.api.edit);
console.log(invalidUrl); // "/error" + предупреждение в консоль
*/
