import Pagination from 'rsuite/Pagination'

import styled from 'styled-components'
import { device } from 'components/common/constants/device'
import { Select } from 'components/Shared/Inputs'

const Wrap = styled.div`
  position: relative;
  width: 100%;
  margin-top: ${({ marginoff }) => (marginoff ? '0' : '16px')};
  padding-left: ${({ paddingLeft }) => paddingLeft && '16px'};

  ${({ custumPagination }) =>
    custumPagination &&
    `
      display: flex;
      justify-content: space-between;
  `}
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const ContainerPagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 175px;
`

const ButtonPagination = styled.button`
  padding: 2px 8px;
  border-radius: 6px;
  min-width: 25.5px;
  transition: color 0.15s ease-out, background-color 0.15s ease-out;
  background-color: #fff;

  &:hover {
    background-color: #e5e5ea;
  }
`

const ArrowPagination = styled.svg`
  width: 1em;
  height: 1em;
  fill: #2f455c;
  cursor: pointer;
`

const CustomPagination = styled(Pagination)`
  .rs-pagination-xs .rs-pagination-btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    max-width: 14px;
  }

  .rs-pagination-btn.rs-pagination-btn-active {
    color: var(--primary-3);
    border-color: transparent;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn-xs {
    border: 1px solid var(--bright);

    padding: 9px 12px;
  }

  .rs-icon {
    font-size: 26px !important;
  }

  .rs-pagination-btn:disabled,
  .rs-pagination-btn.rs-btn-disabled {
    opacity: 0.4;
  }

  .rs-picker-toggle-value span {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--primary-3);
  }

  .rs-picker-toggle-value :nth-child(3) {
    display: none;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
  .rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
    top: 7px;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn-xs {
    padding-right: 40px;
  }

  .rs-picker-default
  .rs-picker-toggle.rs-btn-xs
  .rs-picker-toggle-indicator
  .rs-picker-caret-icon,
  .rs-picker-default
  .rs-picker-toggle.rs-btn-xs
  .rs-picker-toggle-indicator
  .rs-picker-clean {
    top: 7px;
  }

  .rs-picker-default .rs-picker-toggle,
  .rs-stack-item {
    overflow: visible !important;
  }
`

const TotalRown = styled.div`
  position: absolute;
  top: 14px;
  left: 110px;
  font-size: 13px;
  color: var(--primary-3);
  opacity: 0.4;

  @media ${device.mobile} {
    left: 100px;
  }

  ${({ custumPagination }) =>
    custumPagination &&
    `
    position: static;
    left: 250px;
  `}
`

const limitOptions = [10, 20, 30, 40, 50]
const BiglimitOptions = [50, 100, 200, 500, 1000]
const defaultOptions = [
  {
    value: 10,
    label: 10
  },
  {
    value: 20,
    label: 20
  },
  {
    value: 30,
    label: 30
  }
]

const Component = ({
  total = 200,
  limit = 10,
  activePage,
  onChangePage,
  onChangeLimit,
  shownRows = 0,
  pageOff,
  paddingLeft,
  bigStep,
  blind,
  marginoff,
  totalPages,
  custumPagination = false
}) => {
  const paginationInfo = blind
    ? `Showing ${shownRows}`
    : `Showing ${shownRows} of ${total}`

  // ----------- custumPagination -----------
  const currentPage = Number(activePage)
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === totalPages

  const back = () => {
    if (isFirstPage) return
    onChangePage(currentPage - 1)
  }
  const forward = () => {
    if (isLastPage) return
    onChangePage(currentPage + 1)
  }
  const changeCurrentPage = ({ target }) => {
    const { textContent } = target

    onChangePage(textContent)
  }

  const renderPageNumbers = () => {
    if (totalPages <= 3) {
      // Если всего страниц 3 или меньше - рендер их всех
      return (
        <>
          {[...Array(totalPages).keys()].map((page) => (
            <ButtonPagination
              onClick={(e) => changeCurrentPage(e)}
              key={page + 1}
            >
              {page + 1}
            </ButtonPagination>
          ))}
        </>
      )
    }

    if (isFirstPage) {
      return (
        <>
          <ButtonPagination onClick={(e) => changeCurrentPage(e)}>
            {currentPage}
          </ButtonPagination>
          <ButtonPagination onClick={(e) => changeCurrentPage(e)}>
            {currentPage + 1}
          </ButtonPagination>
          <ButtonPagination onClick={(e) => changeCurrentPage(e)}>
            {currentPage + 2}
          </ButtonPagination>
        </>
      )
    }

    if (isLastPage) {
      return (
        <>
          <ButtonPagination onClick={(e) => changeCurrentPage(e)}>
            {totalPages - 2}
          </ButtonPagination>
          <ButtonPagination onClick={(e) => changeCurrentPage(e)}>
            {totalPages - 1}
          </ButtonPagination>
          <ButtonPagination onClick={(e) => changeCurrentPage(e)}>
            {totalPages}
          </ButtonPagination>
        </>
      )
    }

    if (currentPage === 2) {
      return (
        <>
          <ButtonPagination onClick={(e) => changeCurrentPage(e)}>
            {currentPage - 1}
          </ButtonPagination>
          <ButtonPagination onClick={(e) => changeCurrentPage(e)}>
            {currentPage}
          </ButtonPagination>
          <ButtonPagination onClick={(e) => changeCurrentPage(e)}>
            {currentPage + 1}
          </ButtonPagination>
        </>
      )
    }

    // Если текущая страница - где-то в середине
    return (
      <>
        <ButtonPagination onClick={(e) => changeCurrentPage(e)}>
          {currentPage - 1}
        </ButtonPagination>
        <ButtonPagination onClick={(e) => changeCurrentPage(e)}>
          {currentPage}
        </ButtonPagination>
        <ButtonPagination onClick={(e) => changeCurrentPage(e)}>
          {currentPage + 1}
        </ButtonPagination>
      </>
    )
  }

  const pageNumbers = []

  if (CustomPagination)
    for (let i = 0; i <= Math.ceil(total / limit) - 1; i++) {
      pageNumbers.push(i + 1)
    }

  const setCount = (value) => onChangeLimit(value)

  if (custumPagination) {
    return (
      <Wrap custumPagination={custumPagination}>
        <Row>
          <Select
            onChangeLimit={onChangeLimit}
            defaultValue={limit}
            returnValue={setCount}
            options={defaultOptions}
            containerStyles={{
              width: '80px',
              'margin-right': '10px'
            }}
            listStyles={{
              top: '-160px'
            }}
          />
          <TotalRown custumPagination={custumPagination}>
            {paginationInfo}
          </TotalRown>
        </Row>

        <ContainerPagination>
          <ArrowPagination
            className="rs-icon"
            aria-label="page previous"
            aria-hidden="true"
            viewBox="0 0 7 14"
            focusable="false"
            data-category="legacy"
            onClick={back}
          >
            <path d="M2.333 7l3.5-4-.583-.667L1.167 7l4.083 4.667.583-.667z"></path>
          </ArrowPagination>
          {renderPageNumbers()}
          <ArrowPagination
            className="rs-icon"
            aria-label="page next"
            aria-hidden="true"
            viewBox="0 0 7 14"
            focusable="false"
            data-category="legacy"
            onClick={forward}
          >
            <path d="M4.667 7l-3.5 4 .583.667L5.833 7 1.75 2.333 1.167 3z"></path>
          </ArrowPagination>
        </ContainerPagination>
      </Wrap>
    )
  }

  return (
    <Wrap paddingLeft={paddingLeft} marginoff={marginoff}>
      <CustomPagination
        layout={pageOff ? ['limit'] : ['limit', '-', 'pager']}
        size={'xs'}
        prev={true}
        next={true}
        // first={true}
        total={+total}
        limit={+limit}
        limitOptions={bigStep ? BiglimitOptions : limitOptions}
        maxButtons={blind ? 1 : 3}
        activePage={+activePage}
        onChangePage={onChangePage}
        onChangeLimit={onChangeLimit}
      />
      <TotalRown>{paginationInfo}</TotalRown>
    </Wrap>
  )
}

export default Component
